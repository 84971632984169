import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import API from '@/helpers/api';
import { modulesToClearOnLogout } from '../plugins/persist';
import checkUserInKodama from '../../services/auth'

export default {
  async loginUser(context, { user, rememberMe, persistent = true }) {
    const existsInKodama = await checkUserInKodama(user.email)

    if (existsInKodama) {
      // Redirect to GEN2
      const baseUrl = process.env.VUE_APP_ASHITAKA_URL.startsWith('http') 
        ? process.env.VUE_APP_ASHITAKA_URL 
        : `https://${process.env.VUE_APP_ASHITAKA_URL}`
      window.location.href = `${baseUrl}/login`
      return null
    }
    const result = await API(context).post('/login', user)

    if (result.token) {
      const userDetails = _.get(result, 'user');
      const userString = JSON.stringify({
        token: result.token,
        timestamp: moment(),
        rememberMe,
        userDetails,
        isPopup: false,
      });
      // NOTE: Using local storage for both rememner me and normal logins
      // sessionStorage.setItem('__bs_valid', userString);
      if (persistent) {
        localStorage.setItem('__bs_valid', userString);
        context.commit('SET_USER_TOKEN', result.token);
        context.commit('SET_USER', userDetails);
      }
      if (window.applicationBroadcastChannel) {
        window.applicationBroadcastChannel.postMessage('LOGIN');
      }
    }
    return result;
  },
  logoutUser(context) {
    localStorage.removeItem('__bs_valid');
    localStorage.removeItem('BS_ONBOARDING_INFO');
    localStorage.removeItem('BS_ONBOARDING_EMAIL');
    localStorage.removeItem('vuex');
    // sessionStorage.setItem('__bs_valid', null);
    _.map(modulesToClearOnLogout, (module) => {
      context.commit(`${module}/RESET`, null, { root: true });
    });
    context.commit('RESET_UPLOAD_ITEMS', null, { root: true });
    context.commit('RESET_DOWNLOAD_ITEMS', null, { root: true });
    if (window.applicationBroadcastChannel) {
      window.applicationBroadcastChannel.postMessage('LOGOUT');
    }
  },
  async registerUser(context, user) {
    const result = await API(context).post('/register', user);
    if (!result.token) return result;
    const userDetails = _.get(result, 'user');
    const userString = JSON.stringify({
      token: result.token,
      timestamp: moment(),
      userDetails,
      isPopup: false,
      rememberMe: true, // making remember me true for new users on signup
    });
    localStorage.setItem('__bs_valid', userString);
    context.commit('SET_USER_TOKEN', result.token);
    context.commit('SET_USER', userDetails);
    if (window.applicationBroadcastChannel) {
      window.applicationBroadcastChannel.postMessage('LOGIN');
    }
    return result;
  },

  async creativeGetStarted(context, user) {
    const result = await API(context).post('/onboarding/creative/get-started', user);
    if (!result.token) return result;
    context.commit('SET_USER', result.user);
    if (window.applicationBroadcastChannel) {
      window.applicationBroadcastChannel.postMessage('LOGIN');
    }
    return result;
  },
  recoverUser(context, user) {
    return API(context).post('/forgot_password', user);
  },
  setPassword(context, { user, type }) {
    return API(context).post(`/${type}_password`, user);
  },
  changePassword(context, { user }) {
    return API(context).put('users/change_password', user);
  },
  confirmEmail(context, { confirmToken }) {
    return API(context).post('/verify_email_token', { confirmToken });
  },
  updateUser({ commit }, user) {
    commit('UPDATE_USER', user);
  },
  fetchUsers(context, roleName) {
    return API(context).get(`/users/users_list?role=${roleName}`);
  },
  fetchBusinesses(context) {
    return API(context).get('/business/list');
  },
  // getUserDetails(context, id) {
  //   return API(context).get(``)
  // },
  async getCreativesList(context, sweepId) {
    const result = await API(context).get(`/users/creativesList?sweepId=${sweepId}`);
    if (result.success) {
      context.commit('SET_CREATIVE_LIST', [...result.creativesList]);
    }
    return result;
  },
  fetchAdminUsersList(context, payload) {
    const roleName = payload.roleName || 'creative';
    const orderBy = payload.orderBy || 'ASC';
    const sortBy = payload.sortBy || 'firstName';
    const status = payload.status || '';
    const searchBy = payload.searchBy || '';
    const api = '/admin/users/users_role_list';
    return API(context).get(api,
      {
        params: {
          skillIds: payload.skillIds,
          segmentIds: payload.segmentIds,
          role: roleName,
          perPage: 'ALL',
          orderBy,
          sortBy,
          status,
          searchBy,
        },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
  },

  async updateScheduleACallStatus(context, payload) {
    const { eventURI } = payload;
    const lastSavedEventURI = localStorage.getItem('lastSavedEventURI') || '';
    if (lastSavedEventURI === eventURI) {
      return null;
    }
    localStorage.setItem('lastSavedEventURI', eventURI);
    const result = await API(context).put('/users/schedule_call_status_update', payload);
    if (result.success) {
      context.commit('UPDATE_USER', result.user);
      context.commit('ADD_EVENT', result.event);
      return result.event;
    }
    return null;
  },

  async getActivityAlerts(context) {
    const result = await API(context).get('/user_activity_alerts/get_activity_alert');
    if (result.success) {
      context.commit('SET_ACTIVITY_ALERTS', result.activityAlerts);
    }
    return result;
  },

  async getCreativeReports(context) {
    const result = await API(context).get('/admin/users/creatives_report');
    if (result.success) {
      context.commit('SET_CREATIVES_REPORTS', result);
    }
    return result;
  },

  async getCreativeAvailability(context) {
    const result = await API(context).get('/creative_availability');
    if (result.success) {
      context.commit('CREATIVE_AVAILABILITY', result.availability);
    }
    return result;
  },
  async updateCreativeAvailability(context, payload) {
    const result = await API(context).post('/creative_availability/update', payload);
    if (result.success) {
      context.commit('CREATIVE_AVAILABILITY', result.availability);
    }
    return result;
  },
  async updateActivityAlert(context, payload) {
    const result = await API(context).put('/user_activity_alerts/update_activity_alerts', payload);
    if (result.success) {
      context.commit('SET_ACTIVITY_ALERTS', result.alerts);
    }
    return result;
  },

  async addPayment(context, payload) {
    const result = await API(context).post('/stripe/addPayment', payload);
    return result;
  },

  async paymentMethods(context, businessId) {
    const result = await API(context).get(`/stripe/paymentMethod/${businessId}`);
    return result;
  },

  async updatePaymentCard(context, payload) {
    const result = await API(context).put('/stripe/updatePaymentMethod', payload);
    return result;
  },

  async removePaymentCard(context, id) {
    const result = await API(context).delete(`/stripe/detach/${id}`);
    return result;
  },

  async pdfFormat(context, id) {
    const result = await API(context).get(`/stripe/invoice/${id}`);
    return result;
  },

  async cardsPaidList(context, customerId) {
    const result = await API(context).get(`/stripe/invoicesList/${customerId}`);
    return result;
  },

  async paymentMethod(context, payload) {
    const result = await API(context).post('/stripe/stripe_payment', payload);
    return result;
  },

  async applyCoupons(context, payload) {
    const result = await API(context).post('/stripe/stripe_coupon', payload);
    return result;
  },

  async setBundles(context, id) {
    const result = await API(context).get(`/bundles?businessId=${id}`);
    return result;
  },

  resendCreativeVideoEmail(context) {
    return API(context).post('/users/video_email');
  },

  addMember(context, payload) {
    return API(context).post('/users/add_member', payload);
  },

  organizationList(context) {
    return API(context).get('/projects/list/organizations');
  },

  unAffiliateList(context) {
    return API(context).get('/projects/unAfflicate/businesses');
  },

  affiliateBusiness(context, payload) {
    return API(context).post('/projects/businesses/afflicate', payload);
  },

  async updateMember(context, payload) {
    const result = await API(context).put('/users/update_member', payload);
    if (result.success) {
      payload.setRole();
    } else {
      payload.unSetRole();
    }
  },

  deleteMember(context, payload) {
    return API(context).delete('/users/delete_member/', { data: payload });
  },
  async creativeStatusUpdate(context, profileObj) {
    const result = await API(context).put('/users/update_availability', profileObj);
    if (result.success) {
      const localStoreData = JSON.parse(localStorage.getItem('__bs_valid'));
      const {
        userDetails,
        token,
        timestamp,
        rememberMe,
      } = localStoreData;
      const userDetailsObj = Object.assign({},
        { ...userDetails },
        { availability: profileObj.availability });
      const userString = JSON.stringify({
        token, timestamp, rememberMe, userDetails: userDetailsObj,
      });
      let { user } = context.state;
      user = Object.assign({}, { ...user, availability: profileObj.availability });
      localStorage.setItem('__bs_valid', userString);
      context.commit('SET_USER', user);
    }
    return result;
  },
  async getAuthInfo(context) {
    const result = await API(context).get('/auth/user_info');
    if (result.token) {
      const userDetails = _.get(result, 'user');
      const userString = JSON.stringify({
        token: result.token,
        timestamp: moment(),
        rememberMe: false,
        userDetails,
        isPopup: false,
      });
      // NOTE: Using local storage for both rememner me and normal logins
      localStorage.setItem('__bs_valid', userString);
      // sessionStorage.setItem('__bs_valid', userString);
      context.commit('SET_USER_TOKEN', result.token);
      context.commit('SET_USER', userDetails);
    }
    return result;
  },

  async getInviteeInfo(context, token) {
    const result = await API(context).get(`/get_inviteeInfo?confirmToken=${token}`);
    return result;
  },
  async setUserRoleData(context, payload) {
    const result = await API(context).post('/auth/set_user', payload);
    if (result.token) {
      const userDetails = _.get(result, 'user');
      const userString = JSON.stringify({
        token: result.token,
        timestamp: moment(),
        userDetails,
        isPopup: false,
      });
      localStorage.setItem('__bs_valid', userString);
      context.commit('SET_USER_TOKEN', result.token);
      context.commit('SET_USER', userDetails);
    }
    return result;
  },
  async setBusinessInvitee(context, payload) {
    const result = await API(context).post('/set_business_user', payload);
    return result;
  },
  async resendInvite(context, payload) {
    const result = await API(context).post('/resend_invite', payload);
    return result;
  },
  async updateLanguage(context, payload) {
    const result = await API(context).post('users/update_user_language', { preferredLanguage: payload });
    if (result.success) {
      context.commit('SET_USER', result.user);
      return true;
    }
    return false;
  },
  async userEvents(context, businessId) {
    const result = await API(context).get(`/users/events?businessId=${businessId}&timezone=${momentTimezone.tz.guess()}`);
    if (result.success) {
      context.commit('SET_EVENTS', result.events);
      return true;
    }
    return false;
  },
  async creativeEarnings(context) {
    const result = await API(context).get(`/users/earnings?timeZone=${momentTimezone.tz.guess()}`);
    return result;
  },
};
