import Vue from 'vue'
import { StripePlugin } from '@vue-stripe/vue-stripe'
import Vuelidate from 'vuelidate'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VueCalendly from 'vue-calendly'
import VueAnalytics from 'vue-analytics'
import VueClipboard from 'vue-clipboard2'
import { abilitiesPlugin } from '@casl/vue'
import VueApexCharts from 'vue-apexcharts'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import LogRocket from 'logrocket'

import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'
import hyperTranslate from './plugins/HyperTranslate/plugin'
import store from './store'
import 'tiptap-vuetify/dist/main.css'
import './styles/global.css'

LogRocket.init('qkysxa/blended-sense')

try {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          '*.blendedsense.com',
          '*.netlify.app',
          /^\//,
        ], // have to add stage domain here
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
} catch (error) {
  // eslint-disable-next-line no-console
  console.log('Sentry error: ', error)
}

// Lastly link LogRocket and Sentry - LogRocket sessions will show up
// in any Sentry crash reports.
LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL)
  })
})

Vue.use(Vuelidate)
Vue.use(hyperTranslate)
Vue.use(VueCalendly)
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-165591276-1',
  router,
})

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
}

Vue.use(StripePlugin, options)

Vue.use(abilitiesPlugin, store.getters.ability)

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'md',
})

Vue.use(VueClipboard)
VueClipboard.config.autoSetContainer = true

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

window.addEventListener('message', (event) => {
    if (event.data.type === 'LOGOUT') {

      router.app.$store.dispatch('user/logoutUser')
    }
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
