async function checkUserInKodama(email) {
  try {
    const query = `
      query VerifyUserExists($email: String!) {
        verifyUserExists(email: $email) {
          exists
          userId
        }
      }
    `

    const response = await fetch(`${process.env.VUE_APP_KODAMA_API}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { email },
      }),
    }).then((r) => r.json())

    return response?.data?.verifyUserExists?.exists || false
  } catch (error) {
    console.error('Error checking user in Kodama:', error)
    return false
  }
}

export default checkUserInKodama
